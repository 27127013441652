import React, { useState } from 'react';
import LocationSelector from '../../components/LocationSelector';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useAuth } from "../../contexts/authContext"; // Import AuthContext
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import '../../cssFiles/account.css';
import '../../cssFiles/questionnaire.css';
import { CreatorSignupSchema } from "../../utils/validationSchemas"; // ✅ Import validation schema

const SignUpC = () => {
    
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
   
      const { signUp } = useAuth();
      const navigate = useNavigate();
     const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission status
  const [formMessage, setFormMessage] = useState(""); // ✅ Track success/error messages

// Function to toggle password visibility
const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    };


    
      return (
        <div className="questionnaire-container">
        <Formik
          initialValues={{ firstName: "", 
            lastName: "", 
            email: "", 
            password: "", 
            country: "", 
            state: "", 
            city: "",
            agreedToTerms: false, // ✅ Ensure checkbox is tracked
            fakeField: ""  }}
          validationSchema={CreatorSignupSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setFormMessage(""); // ✅ Clear any previous messages
            // ❌ If honeypot field is filled, the form is rejected
        if (values.fakeField) {
            console.warn("🚨 Honeypot triggered. Possible bot detected. Blocking submission.");
            setFormMessage("🚨 Submission blocked. Possible bot detected.");
            return;
          }
  
          // ❌ Ensure reCAPTCHA is completed
          if (!recaptchaToken) {
            setFormMessage("🚨 Please complete the reCAPTCHA before submitting.");
            return;
          }
  
          setIsSubmitting(true); // Disable submit button

 
            
            try {
         const response =    await signUp({ ...values, userType: "creator" });
         setFormMessage("✅ Your submission was successful! Redirecting to email verification...");
              if (response?.success) {
                resetForm();  // Reset form on success
                navigate("/emailmsg");  // Redirect user
              }
           
            } catch (error) {
              console.error(error);
              setFormMessage("🚨 An error occurred. Please try again.");
            }
            setIsSubmitting(false); // Re-enable submit button if error occurs
            setSubmitting(false);
          }}
        >
          {formik => (
        
        <Form className="questionnaire-style">
           {/* ✅ Success/Error Message */}
           {formMessage && (
              <div className={`form-message ${formMessage.includes("✅") ? "success" : "error"}`}>
                {formMessage}
              </div>
            )}
        <div className="createhead">
        <h2>Build Your Creator Profile</h2>
        </div>
        <div className="questionnaire-style-heading">
        <p> Complete the information below and enter a password. <br></br> <br></br> After clicking "Create My Account", you will be directed to your email to confirm your account.</p>
        </div>
        <br></br>

        <div className="scrollable-content">
       
       
        <div>  
              <label htmlFor="firstName">First Name</label>
              <Field type="text" 
              id="firstName" 
              name="firstName" 
              placeholder="First Name" 
              autoComplete="given-name" />
               <ErrorMessage name="firstName" component="div" className="error-message" />
        </div>

             <br></br>
        <div>  
              <label htmlFor="lastName">First Name</label>
              <Field type="text" 
              id="lastName" 
              name="lastName" 
              placeholder="Last Name" 
              autoComplete="family-name" />
              <ErrorMessage name="lastName" component="div" className="error-message" />
        </div>       
             

         <br></br>
            <div>
            <label htmlFor="email">Email Address</label>
            <Field name="email" type="email" id="email"  autoComplete="email" placeholder="Email"/>
            <ErrorMessage name="email" component="div" className="error-message" />
            </div>

            <br></br>
              <LocationSelector formik={formik} />
            
              <br></br>

               {/* Honeypot Field (Hidden) - Blocks bots */}
          <Field
            type="text"
            name="fakeField"
            style={{ display: "none" }}
            tabIndex="-1" // Prevent users from accidentally tabbing into it
            autoComplete="off"
          />
  

          {/* Google reCAPTCHA */}
          <ReCAPTCHA
            sitekey="6LftcvopAAAAAIjDtKRd6oYNjRLuLJUFZ3XL9dsp" // Replace with your actual reCAPTCHA site key
            onChange={setRecaptchaToken}
          />
              
             
              <br></br>

            <p style={{ fontSize: '15px', color: '#fc8320', fontStyle: 'bold', }}> Create a password for your account. <br></br> We will offer other sign up options soon.</p>
             
 {/* Password  */}
            <div>
            <label htmlFor="password">Password</label>
            <Field type={showPassword ? "text" : "password"} id="password" name="password" autoComplete="new-password" placeholder="Password"  />
            <ErrorMessage name="password" component="div" className="error-message" />
            </div>

            <br></br>

         {/* Confirm Password */}   
            <div>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Field type={showPassword ? "text" : "password"} id="confirmPassword" name="confirmPassword" autoComplete="new-password" />
            <ErrorMessage name="confirmPassword" component="div" className="error-message" />
            </div>

            <br></br>

            {/* Show Password Checkbox */}
            <label>
                        <input
                        type="checkbox"
                        checked={showPassword}
                        onChange={toggleShowPassword}
                        id="showPassword"
                        />
                        Show Password
            </label>
            <br></br>
            <br></br>

        </div>
           {/* Submit Button: Disabled if recaptcha is not completed or form is submitting */}
          <button className="submit-button" type="submit" disabled={isSubmitting || !recaptchaToken}>
            {isSubmitting ? "Submitting..." : "Create My Account"}
          </button>
           
          <div className="cta-links">
            <Field type="checkbox" name="agreedToTerms" />
            By creating an account, you agree to our {" "}
            <a href="https://neufluence.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
            Terms of Service,{" "}
            </a>
            <a href="https://neufluence.com/privacy_policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
            </a> and our default notifications settings.
            <ErrorMessage name="agreedToTerms" component="div" className="error-message" />
            </div>
           
           
            </Form>
          )}
        </Formik>
        </div>
      );
    }
    

export default SignUpC;
