

import React, { useEffect } from "react";
import '../../cssFiles/dashboardb.css';

const StripeFS = () => {
  useEffect(() => {
   
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

  }, []);

  return (
    <div className="stripe-container">
      <div className="stripe-box">
      <h2>Select Full Service Pricing Plans</h2>
      <p>Click here if you would like to learn more about our full service plans. </p>
    <div className="button-group">
     
          <div style= {{width:1200}}>
          <stripe-pricing-table
            pricing-table-id="prctbl_1OxIq5G3BlD50XxqMqtPAw8L"
            publishable-key="pk_live_51LC6a5G3BlD50XxqCJaptaHIFZZUdxObFg0mLsMSPfzOPbnn7Jyq5jbu22qplCqg1hvl4P5gGknFrsKYMcvP54UR00SzRuzGiu"
          ></stripe-pricing-table>
        </div>
        </div>
        </div>
        </div>
      );
    };
    

export default StripeFS;
