import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/authContext"; // Import AuthContext
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import LoadingSpinner from "../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import '../cssFiles/dashboardb.css';

const BrandAccount = () => {
  const { user, userType, loading } = useAuth(); // Get user info from AuthContext
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updateMessage, setUpdateMessage] = useState("");

  useEffect(() => {
    // ✅ Prevent unauthorized users from accessing
    if (loading) return; // Wait until auth context loads
    if (!user) {
      navigate("/signin");
      return;
    }

    if (userType !== "brand" && userType !== "admin") {
      navigate("/dashboard"); // Restrict creators from accessing
      return;
    }

    const fetchUserData = async () => {
      try {
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setUserData(userSnap.data());
        } else {
          console.error("No user data found");
        }
      } catch (error) {
        console.error("🚨 Error fetching user data:", error);
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [user, userType, loading, navigate]);

  if (isLoading || loading) {
    return <LoadingSpinner />;
  }

  if (!userData) {
    return <div className="error-message">🚨 Error loading profile data.</div>;
  }

  return (
    <div className="accounts-container">
       <div className="accounts-box">

     

      <Formik
        initialValues={{
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          businessName: userData.businessName || "",
          businessWebsite: userData.businessWebsite || "",
          email: userData.email || "",
          country: userData.country || "",
          state: userData.state || "",
          city: userData.city || "",
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required("Required"),
          lastName: Yup.string().required("Required"),
          businessWebsite: Yup.string()
            .url("Invalid URL format")
            .required("Required"),
          country: Yup.string().required("Required"),
          state: Yup.string().required("Required"),
          city: Yup.string().required("Required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const userRef = doc(db, "users", user.uid);
            await updateDoc(userRef, values);

            setUpdateMessage("✅ Profile updated successfully!");
          } catch (error) {
            console.error("🚨 Error updating profile:", error);
            setUpdateMessage("🚨 Error updating profile. Try again.");
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
         <Form className="accounts-style">
        
         <h2>Brand Account Settings</h2>
         <p>Manage your account details below.</p>

         {updateMessage && <div className="success-message" style={{ 
    color: "white", 
    textAlign: "center",
    fontSize: "16px", 
    fontWeight: "bold", 
    marginTop: "10px", 
    padding: "10px", 
    border: "1px rgba(252, 131, 32, 0.51)", 
    borderRadius: "5px", 
    backgroundColor: "#951eb5" 
  }}>{updateMessage}</div>}

         <br></br>
         <div className="form-row">
         <div className="form-group">
           <label htmlFor="firstName">First Name <span style={{ fontSize: "12px", color: "red" }}>(read-only)</span></label>
           <Field type="text" name="firstName" id="firstName" readOnly />
           <ErrorMessage name="firstName" component="div" className="error" />
         </div>
       
         <div className="form-group">
           <label htmlFor="lastName">Last Name <span style={{ fontSize: "12px", color: "red" }}>(read-only)</span></label>
           <Field type="text" name="lastName" id="lastName" readOnly />
           <ErrorMessage name="lastName" component="div" className="error" />
         </div>
         </div>
         
         <div className="form-row">
         <div className="form-group">
           <label htmlFor="email">Email <span style={{ fontSize: "12px", color: "red" }}>(read-only)</span></label>
           <Field type="email" name="email" id="email" readOnly />
         </div>
        </div>

       <div className="form-row">
         <div className="form-group">
           <label htmlFor="businessName">Business Name <span style={{ fontSize: "12px", color: "red" }}>(read-only)</span></label>
           <Field type="text" name="businessName" id="businessName" readOnly />
         </div>
       
         <div className="form-group">
           <label htmlFor="businessWebsite">Business Website</label>
           <Field type="url" name="businessWebsite" id="businessWebsite" />
           <ErrorMessage name="businessWebsite" component="div" className="error" />
         </div>
        </div>
       
      <div className="form-row">
         <div className="form-group">
           <label htmlFor="country">Country</label>
           <Field type="text" name="country" id="country" />
           <ErrorMessage name="country" component="div" className="error" />
         </div>
       
         <div className="form-group">
           <label htmlFor="state">State</label>
           <Field type="text" name="state" id="state" />
           <ErrorMessage name="state" component="div" className="error" />
         </div>
       
        <div className="form-group">
           <label htmlFor="city">City</label>
           <Field type="text" name="city" id="city" />
           <ErrorMessage name="city" component="div" className="error" />
       </div>
       
   </div>

         <div className="button-container">
         <button type="submit" disabled={isSubmitting}>
           {isSubmitting ? "Updating..." : "Update Profile"}
         </button>
       </div>

       <p style={{ fontSize: '15px', color: '#fc8320', fontStyle: 'bold', }}>  
       Email us at signup@neufluence.com if you need to update the "read only" fields. </p>
       </Form>
       
          
        )}
      </Formik>
    </div>
    </div>
  );
};

export default BrandAccount;
