// This code is for Requesting to Reset Your Password and an email will send

import { useState, useEffect } from "react";
import { auth } from '../firebase';
import { 
    sendPasswordResetEmail 
} from "firebase/auth";


const RequestPwrd = () => {

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    

    useEffect(() => {
            // Clear the success message after 5 seconds
            if (successMessage) {
                const timer = setTimeout(() => setSuccessMessage(''), 5000);
                return () => clearTimeout(timer);
            }
        }, [successMessage]);

        const actionCodeSettings = {
            url: 'http://localhost:3000/accountverified', // Ensure this points to the correct screen
            handleCodeInApp: true,
          };
    
    const sendResetEmail = async () => {
            try {
                
                await sendPasswordResetEmail(auth, email, actionCodeSettings);
                setSuccessMessage("Password reset email sent! Please check your inbox.");
                setEmail('');
            } catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                handleFirebaseErrors(errorCode, errorMessage);
            }
        };
    
    const handleSubmit = async (e) => {
            e.preventDefault();
            setError('');
            setSuccessMessage('');
            sendResetEmail();
        };

    

    const handleFirebaseErrors = (errorCode, errorMessage) => {
        if (errorCode === 'auth/invalid-email') {
            setError("Invalid email address.");
        } else if (errorCode === 'auth/user-not-found') {
            setError("User with this email address does not exist.");
        } else {
            setError(errorMessage);
        }
    };
    
  
// Reset Request page
    return ( 

        <div className="form-style">
          
        <form onSubmit={handleSubmit}>
       
        
        <br></br>
        
        <h2>Request Password</h2>
       
        <br></br>
       
        <p>Enter your email address and we'll send you a link to reset your password.</p>
        
        <br></br>
        
        {error && <div style={{ color: 'red' }}>{error}</div>}
        {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
        <br></br>
        
        <label> Email Address</label>
            <input 
            type="email" 
            id="email" 
            name="email" 
            autoComplete="email" 
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
        
        <button type="submit">Send Password Reset Link</button>
        
       
        <br></br>
        
        <p>Having trouble? Contact us at <span className="email-style">signup@neufluence.com</span>.</p>
 </form>
 </div>
 
     );
}
 
export default RequestPwrd
