import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from "./contexts/authContext";

import Navbar from './components/Navbar';
import SignHome from './auth/SignHome';
import SignUpB from './pages/brands/SignUpB';
import SignUpC from './pages/creators/SignUpC';
import BrandsQ from './pages/brands/BrandsQ';
import Campaign from './pages/brands/Campaign';

import AdminDashboard from './admin/AdminDashboard';
import BrandAccount from './admin/BrandAccount';
import SigninForm from './auth/SigninForm'; // Import other components as needed
import ResetPassword from './auth/ResetPassword';
import RequestPwrd from './auth/RequestPwrd';
import VerifyEmailMsg from './auth/VerifyEmailMsg';
import VerifyEmailHandler from './auth/VerifyEmailHandler';
import EmailisVerifiedMsg from './auth/EmailisVerifiedMsg';
import AdminSignup from './admin/AdminSignup';

 import Dashboardb from './pages/brands/Dashboardb';
 import StripeFS from './pages/brands/StripeFS';
 import StripeCreditz from './pages/brands/StripeCreditz';
 import TestCampaign from './pages/campaigns/testCampaign';
 import TestCOverview from './pages/creditz/testcOverview';
 import FSOverview from './pages/campaigns/fsOverview';
 

 import Unauthorized from "./components/Unauthorized"; // ✅ Create a new NotFound component



 const App = () => {

 
  const { user, userType } = useAuth(); // ✅ Get authentication data
  console.log("useAuth Output:", { user, userType });
    return (

      <Router>
     
        <div className="App">
          <Navbar />
          <Routes>

          {/* Redirect "/" to "/neuhome" */}
        <Route path="/" element={<Navigate to="/neuhome" replace />} />


            
             <Route path="/neuhome" element={<SignHome />} />  {/* Set SignHome as the default route */}
            <Route path="/brandsignup" element={<SignUpB/>} />
            <Route path="/creatorsignup" element={<SignUpC/>} />
            <Route path="/passrequest" element={<RequestPwrd />} />
            <Route path="/resetpass" element={<ResetPassword />} />
            <Route path="/emailmsg" element={<VerifyEmailMsg />} />
            <Route path="/VerifyEmailHandler" element={<VerifyEmailHandler/>} />
            <Route path="/accountverified" element={<EmailisVerifiedMsg/>} />
            <Route path="/brandaccount" element={<BrandAccount/>} />
                 {/* Pricing Tables */}
          <Route path="/fspricing" element={<StripeFS/>} />
          <Route path="/crdtzpricing" element={<StripeCreditz/>} />
          <Route path="/onboardbrands" element={<BrandsQ/>} />

            <Route path="/login" element={<SigninForm/>} />
            <Route path="/campaign" element={<Campaign/>} />
            
            <Route path="/Dashboardb" element={<Dashboardb/>} />

            <Route path="/brandsignup/emailmsg" element={<VerifyEmailMsg />} />
          

          {/* New Campaign Pages */}
          <Route path="/testcampaign" element={<TestCampaign/>} />
            <Route path="/testcoverview" element={<TestCOverview/>} />
            <Route path="/fsoverview" element={<FSOverview/>} />


            {/* Define other routes as necessary */}
            <Route path="AdminDashboard" element={<AdminDashboard/>} />
            <Route path="AdminSignup" element={<AdminSignup/>} />

        {/* ✅ Unauthorized Page for Restricted Access */}
        <Route path="/unauthorized" element={<Unauthorized />} />

           
          </Routes>
        </div>
      </Router>

     
    );
}

export default App;
