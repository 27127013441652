import React, { useState } from 'react';
import { Formik, Form, Field,ErrorMessage } from 'formik';
import LocationSelector from '../../components/LocationSelector';

import { useAuth } from "../../contexts/authContext"; // Import AuthContext
import { useNavigate } from "react-router-dom";
import { BrandSchema } from "../../utils/validationSchemas"; // ✅ Import validation schema
import ReCAPTCHA from "react-google-recaptcha";
import '../../cssFiles/account.css';
import '../../cssFiles/questionnaire.css';

const SignUpB = () => {

const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission status


  // Function to toggle password visibility
const toggleShowPassword = () => {
setShowPassword(!showPassword);
  };



  return (
  <div className="questionnaire-container">
  <Formik
  initialValues={{
  firstName: "", 
  lastName: "", 
  businessName: "", 
  businessWebsite: "https://",
  email: "", 
  password: "", 
  country: "", 
  state: "", 
  city: "", 
  agreedToTerms: false, // ✅ Ensure checkbox is tracked
  fakeField: "" // ✅ Ensure honeypot field is included

  }}
  validationSchema={BrandSchema}
  onSubmit={async (values, { setSubmitting, resetForm }) => {
   
    // ❌ If honeypot field is filled, the form is rejected
    if (values.fakeField) {
      console.warn("🚨 Honeypot triggered. Possible bot detected. Blocking submission.");
   
      return;
    }

    // ❌ Ensure reCAPTCHA is completed
    if (!recaptchaToken) {
      alert("Please complete the reCAPTCHA");
      return;
    }

    setIsSubmitting(true); // Disable submit button



    try {

     const response =  await signUp({ ...values, userType: "brand" });

      if (response?.success) {
        resetForm();  // Reset form on success
        navigate("/emailmsg");  // Redirect user
      }  
    } catch (error) {
      console.error(error);
   
      }
      setIsSubmitting(false); // Re-enable submit button if error occurs
      setSubmitting(false);
      }}
  >
  {formik => (

 <Form className="questionnaire-style">

 
         <div className="createhead">
         <h2>Build Your Brand Profile</h2>
         </div>
         <div className="questionnaire-style-heading">
         <p> Complete the information below and enter a password. <br></br> <br></br> After clicking "Create My Account", you will be directed to your email to confirm your account.</p>
         </div>
         <br></br>
 
         <div className="scrollable-content">
        
        
         <div>  
               <label htmlFor="firstName">First Name</label>
               <Field type="text" 
               id="firstName" 
               name="firstName" 
               autoComplete="given-name" />
                <ErrorMessage name="firstName" component="div" className="error-message" />
         </div>
 
              <br></br>
         <div>  
               <label htmlFor="lastName">Last Name</label>
               <Field type="text" 
               id="lastName" 
               name="lastName" 
  
               autoComplete="family-name" />
               <ErrorMessage name="lastName" component="div" className="error-message" />
         </div>       
              
 
          <br></br>
             <div>
             <label htmlFor="email">Email Address</label>
             <Field name="email" type="email" id="email"  autoComplete="email" />
             <ErrorMessage name="email" component="div" className="error-message" />
             </div>
      
       <br></br>
             <div>
        <label htmlFor="businessName">Business Name</label>
        <Field type="text" id="businessName" name="businessName"  autoComplete="organization" />
        <ErrorMessage name="businessName" component="div" className="error-message" />
        </div>
        
        <br></br>       
                    
        <div>
          <label htmlFor="businessWebsite">Business Website</label>
          <Field type="url" id="website" name="businessWebsite" autoComplete="website"  />
          <ErrorMessage name="businessWebsite" component="div" className="error-message" />
          </div>

             <br></br>
               <LocationSelector formik={formik} />

               {/* Honeypot Field (Hidden) - Blocks bots */}
          <Field
            type="text"
            name="fakeField"
            style={{ display: "none" }}
            tabIndex="-1" // Prevent users from accidentally tabbing into it
            autoComplete="off"
          />
  <br></br>
     
              
             
 
             <p style={{ fontSize: '15px', color: '#fc8320', fontStyle: 'bold', }}> Create a password for your account. <br></br> We will offer other sign up options soon.</p>
              
  {/* Password  */}
             <div>
             <label htmlFor="password">Password</label>
             <Field type={showPassword ? "text" : "password"} id="password" name="password" autoComplete="new-password"  />
             <ErrorMessage name="password" component="div" className="error-message" />
             </div>
 
             <br></br>
 
          {/* Confirm Password */}   
             <div>
             <label htmlFor="confirmPassword">Confirm Password</label>
             <Field type={showPassword ? "text" : "password"} id="confirmPassword" name="confirmPassword" autoComplete="new-password" />
             <ErrorMessage name="confirmPassword" component="div" className="error-message" />
             </div>
 
             <br></br>
 
             {/* Show Password Checkbox */}
             <label>
                         <input
                         type="checkbox"
                         checked={showPassword}
                         onChange={toggleShowPassword}
                         id="showPassword"
                         />
                         Show Password
             </label>
             <br></br>
                  {/* Google reCAPTCHA */}
           {/* Google reCAPTCHA */}
           <ReCAPTCHA
            sitekey="6LftcvopAAAAAIjDtKRd6oYNjRLuLJUFZ3XL9dsp" // Replace with your actual reCAPTCHA site key
            onChange={setRecaptchaToken}
  />
             <br></br>
 
         </div>
           {/* Submit Button: Disabled if recaptcha is not completed or form is submitting */}
          <button className="submit-button" type="submit" disabled={isSubmitting || !recaptchaToken}>
            {isSubmitting ? "Submitting..." : "Create My Account"}
          </button>
            
          <div className="cta-links">

              {/* ✅ Error message above the checkbox */}
          <ErrorMessage name="agreedToTerms" component="div" className="error-message" />
          <Field type="checkbox" name="agreedToTerms"  />
        
          By creating an account, you agree to our {" "}
          <a href="https://neufluence.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
          Terms of Service,{" "}
          </a>
          <a href="https://neufluence.com/privacy_policy/" target="_blank" rel="noopener noreferrer">
          Privacy Policy
          </a> and our default notifications settings.
          
          </div>

  </Form>
  )}
  </Formik>
  </div>
  );
  }
  export default SignUpB;
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  