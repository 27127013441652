

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../cssFiles/account.css';
import '../cssFiles/navbar.css';

const SignHome = () => {
const [selectedOption, setSelectedOption] = useState(''); // State to track the selected option
const navigate = useNavigate();
const [errorMessage, setErrorMessage] = useState(''); // State for error message

// Function to handle selection change
const handleSelectChange = (event) => {
setSelectedOption(event.target.value); // Update selected option state
setErrorMessage('');
};

// Function to handle button click and redirect based on selected option
const handleButtonClick = (event) => {
    event.preventDefault(); // Prevent form submission
if (selectedOption === 'brand') {
navigate('/brandsignup'); // Redirect to Brand component
} else if (selectedOption === 'creator') {
navigate('/creatorsignup'); // Redirect to Influencer component
} else {
// Handle case where no option is selected
setErrorMessage('Please select a role.');
}
};



return (
<div className="sign-home">
<form>

<div className="container-style">
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20px', marginBottom: '20px' }}>
        <a href="https://www.neufluence.com" target="_blank" rel="noopener noreferrer">
          <img src='./assets/images/Neu_beta.png' alt="Company Logo" className="logo" />
        </a>
 </div>


<h2>Welcome to Neufluence!</h2>
<p>Select the account type below to get started, then choose Create Account.</p>

<select name="type" className="custom-select signup" onChange={handleSelectChange} value={selectedOption}>
<option value="" disabled>Select account type</option>
<option value="brand">Brand</option>
<option value="creator">Creator</option>
</select>

  {/* Error message displayed here in red */}
  {errorMessage && <p style={{ color: 'red', fontWeight: 'bold', marginTop: '2px' }}>{errorMessage}</p>}
    
    <button type="button" onClick={handleButtonClick} className="create-button "

    >CREATE ACCOUNT</button>




    <div className="cta-links">
    <p>
    Already signed up? Click{' '}
    <a href="/login" className="here-link" style={{ fontSize: '16px', color: '#fc8320',
    }}>
    here
    </a>{' '}
    to access your account.
    </p>
    </div>

    
    <br></br>
    <br></br>
    <br></br>
    <div className="signup1-links">
    <a style={{ color: '#d211d2', fontWeight: 'bold', textAlign:'center', textDecoration: 'none', }}
    href="https://neufluence.com"
    target="_blank"
    rel="noopener noreferrer"
    >
    --Back to Neufluence Home
    </a>

    </div>

    </div>

    </form>
    </div>
    );
    };

    export default SignHome;