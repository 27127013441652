import { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";

export default function LocationSelector({ formik }) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Fetch countries and prioritize US and Canada
    const sortedCountries = Country.getAllCountries().sort((a, b) => {
      if (a.name === "United States" || a.name === "Canada") return -1;
      if (b.name === "United States" || b.name === "Canada") return 1;
      return a.name.localeCompare(b.name);
    });

    setCountries(sortedCountries);
  }, []);

  /**
   * Handles Country Selection: Resets State and City fields.
   */
  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    formik.setFieldValue("country", countryCode);
    formik.setFieldTouched("country", true);

    // Reset states and cities
    setStates(State.getStatesOfCountry(countryCode) || []);
    setCities([]);
    formik.setFieldValue("state", "");
    formik.setFieldValue("city", "");
  };

  /**
   * Handles State Selection: Resets City field.
   */
  const handleStateChange = (e) => {
    const stateCode = e.target.value;
    formik.setFieldValue("state", stateCode);
    formik.setFieldTouched("state", true);

    // Reset cities and fetch new ones
    const cityList = City.getCitiesOfState(formik.values.country, stateCode) || [];
    setCities(cityList);
    formik.setFieldValue("city", "");
  };

  return (
    <>
      {/* Country Dropdown (US & Canada appear first) */}
      <select name="country" value={formik.values.country} onChange={handleCountryChange}>
        <option value="">Select Country</option>
        {countries.map(({ isoCode, name }) => (
          <option key={isoCode} value={isoCode}>
            {name}
          </option>
        ))}
      </select>

      {/* State Dropdown (Only Appears If Country Is Selected) */}
      {states.length > 0 && (
        <select name="state" value={formik.values.state} onChange={handleStateChange}>
          <option value="">Select State</option>
          {states.map(({ isoCode, name }) => (
            <option key={isoCode} value={isoCode}>
              {name}
            </option>
          ))}
        </select>
      )}

      {/* City Dropdown (Fixes the "Objects are not valid as a React child" error) */}
      {cities.length > 0 && (
        <select name="city" value={formik.values.city} onChange={(e) => formik.setFieldValue("city", e.target.value)}>
          <option value="">Select City</option>
          {cities.map((city) => (
            <option key={city.name} value={city.name}>
              {city.name} {/* Ensure we're rendering only the name property */}
            </option>
          ))}
        </select>
      )}
    </>
  );
}
