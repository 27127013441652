  // takes care of initializing Firebase and Firestore, which you can then import and use wherever needed in your application.
  
  //  contains the Firebase initialization and export the initialized services (db and auth).

  // Firebase App Initialization: initializeApp(firebaseConfig) initializes your Firebase application.

// Firebase Authentication: getAuth(app) initializes the Authentication service. You can use auth for handling user authentication in your app.

//Firebase Firestore: getFirestore(app) initializes Firestore. You can use db to interact with your Firestore database.


import { 
    initializeApp } 
from 'firebase/app';

import { 
    getAuth,
} from 'firebase/auth';

import {
    getFirestore, 
    } from 'firebase/firestore';

import { 
  getStorage 
} from 'firebase/storage';

const firebaseConfig = {

  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

// init firebase app
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Make sure this line calls getStorage


export { auth, db, storage };