import { db } from "../firebase";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";


// Update user status (suspend/ban/reactivate)
export const updateUserType = async (userId, newType) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { status: newType });
    return { success: true };
  } catch (error) {
    console.error("Error updating user status:", error);
    return { success: false, error };
  }
};


// delete usrrr
export const deleteUser = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { userType: "deleted"});
    return { success: true };
  } catch (error) {
    console.error("Error deleting user:", error);
    return { success: false, error };
  }
};

// ✅ Function to update a user's role
export const promoteToAdmin = async (userId) => {
    return updateUserType(userId, "admin" );

};

// Fetch all users
export const fetchAllUsers = async () => {
  try {
    const usersCollection = collection(db, "users");
    const snapshot = await getDocs(usersCollection);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};



// Send password reset email
export const resetUserPassword = async (email) => {
  try {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
    return { success: true };
  } catch (error) {
    console.error("Error sending password reset email:", error);
    return { success: false, error };
  }
};

export const getCollectionTotals = async () => {
  try {
    const usersCollection = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollection);
    const totalUsers = usersSnapshot.size; 
    const totalBrands = usersSnapshot.docs.filter((doc) => doc.data().userType === "brand").length; 
    const totalCreators = usersSnapshot.docs.filter((doc) => doc.data().userType === "creator").length;
    const totalAdmins = usersSnapshot.docs.filter((doc) => doc.data().userType === "admin").length; 
    return { totalUsers, totalBrands, totalCreators, totalAdmins };
    
  } catch (error) {
    console.error("Error fetching totals:", error);
    return {};
  }
};