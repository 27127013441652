
// This is the code that verifies the user's email in Firebase when the user clicks the link in their email.
// all state changes are handled in authcontext.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    applyActionCode, 
} from 'firebase/auth';

import { 
    auth, 
    
} from '../firebase';

import LoadingSpinner from '../components/LoadingSpinner';
import firebaseErrorMessages from '../utils/firebaseErrors';

    const VerifyEmailHandler = () => {
         const [message, setMessage] = useState('Verifying your email...');
         const [isLoading, setIsLoading] = useState(true); // Loading state
         const navigate = useNavigate();
         
    
     // Function to translate Firebase error codes to user-friendly messages

   

     useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('oobCode'); // Extract oobCode from the URL
    
        if (!code) {
            setMessage('No verification code found in the URL.');
            setIsLoading(false);
            return;
        }
    
        const verifyEmail = async () => {
            try {
                await applyActionCode(auth, code); // Use oobCode for verification
                await auth.currentUser?.reload(); // Ensure updated state
    
                const user = auth.currentUser;
                if (user && user.emailVerified) {
                    navigate('/emailverified');
                } else {
                    setMessage('Your email has been verified, we are o log in.');
                }
            } catch (error) {
                console.error('Email verification error:', error);
                setMessage(firebaseErrorMessages(error));
            } finally {
                setIsLoading(false);
            }
        };
    
        verifyEmail();
    }, [navigate]);
    
    


            return (
                <div className="verify-email-handler">
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <div>
                            <h1>Email Verification Status</h1>
                            <p>{message}</p>
                            {/* Additional options for the user in case of an error */}
                        </div>
                        )}
                    </div>
                    );
                };

export default VerifyEmailHandler;
