import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from "../contexts/authContext"; 
import Modal from './Modal';


const Navbar = () => {
  const { user, signOutUser, userType, isEmailVerified, loading } = useAuth(); // Corrected auth context reference
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const avatarRef = useRef(null);

  
  // Handle clicking outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        avatarRef.current && !avatarRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDropdown = () => setDropdownVisible((prev) => !prev);

  const handleSignOut = () => {
    setShowModal(true);
    setSuccessMessage(false);
  };

  const confirmSignOut = async () => {
    setIsSigningOut(true);
    try {
      await signOutUser(); // Corrected function name from `logOut` to `signOutUser`
      setSuccessMessage(true);
      setTimeout(() => {
        setShowModal(false);
        setIsSigningOut(false);
        navigate('/login', { state: { successMessage: true } });
      }, 2000);
    } catch (error) {
      console.error('Error signing out:', error);
      setIsSigningOut(false);
    }
  };

  // Simplifies programmatic navigation.

  const handleNavigation = (path) => navigate(path);

   /**
   * Hide Navbar on `/neuhome` page
   */
   if (location.pathname === "/neuhome") {
    return null;
  }

  // Hide Navbar completely on the verify email page
  if (
    location.pathname === "/emailmsg" || // ✅ Hide on email confirmation screen
    location.pathname === "/accountverified" || // ✅ Hide when verifying email
    location.pathname === "/neuhome" // ✅ Hide on login page
  ) {
    return null; // ✅ Navbar will NOT render on these pages
  }
  

  // Only show the LOG IN button on the request password page
  if (location.pathname === "/requestpass") {
    return (
      <nav className="navbar" aria-label="Main Navigation">
        <RenderButton path="/login" text="LOG IN" className="login-button" />
      </nav>
    );
  }
  
  
  const RenderButton = ({ path, text, className }) => (
    <button onClick={() => handleNavigation(path)} className={className}>
      {text}
    </button>
  );

  
 
 
  const handleCloseModal = () => {
    setShowModal(false);
    setSuccessMessage(false);
  };

  return (
    <nav className="navbar" aria-label="Main Navigation">
      <div>
        <a href="https://www.neufluence.com" target="_blank" rel="noopener noreferrer">
          <img src='./assets/images/Neu_beta.png' alt="Company Logo" className="logo" />
        </a>
      </div>

      <div> {/* ✅ Wrap everything inside a parent div */}
      {!loading && user && isEmailVerified && location.pathname !== "/login" ? (
        // ✅ Show avatar & dropdown only after authentication is verified
        <div>
          <img
            src={user.photoURL || "./NEU-LOGO.ico"}
            alt="User Avatar"
            className="nav-avatar"
            onClick={toggleDropdown}
            ref={avatarRef}
            role="button"
            aria-label="User menu"
          />
          {dropdownVisible && (
            <div ref={dropdownRef} className="nav-dropdown" aria-label="User dropdown">
              {userType === "admin" && (
                <Link to="/admindashboard">Admin Dashboard</Link> // ✅ Show only for admins
              )}
              <Link to="/dashboardb">My Dashboard</Link>
              <Link to="/brandaccount">My Account</Link>
              <button onClick={handleSignOut} className="sign-out-link">Sign Out</button>
            </div>
          )}
        </div>
      ) : (
        // ✅ Show Sign Up/Login buttons only if user is NOT logged in
        <>
       {/* ✅ Show "Create Account" only if logged out and on login page */}
       {!user && location.pathname === "/login" && (
              <RenderButton path="/neuhome" text="CREATE ACCOUNT" className="signup-button" />
            )}

          {/* Hide login button on SignHome.js, BrandSignup, and CreatorSignup */}
          {!["/login", "/brandsignup", "/creatorsignup"].includes(location.pathname) && (
            <RenderButton path="/login" text="LOG IN" className="login-button" />
          )}
        </>
      )}
    </div> {/* ✅ Closing the parent div */}

      {/* Sign Out Modal */}
      <Modal show={showModal} onClose={handleCloseModal}>
        {successMessage ? (
          <p>You have been signed out successfully.</p>
        ) : (
          <>
            <p>You are about to sign out. Are you sure?</p>
            <button onClick={confirmSignOut} className="confirmout-button" disabled={isSigningOut}>
              {isSigningOut ? 'Signing Out...' : 'Confirm Sign Out'}
            </button>
          </>
        )}
        <br />
        <button onClick={handleCloseModal} className="close-button" disabled={isSigningOut}>
          Close
        </button>
      </Modal>
    </nav>
  );
};

export default Navbar;
