        
import { useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import Modal from "../../components/Modal"; // ✅ Ensure you have a modal component
import '../../cssFiles/dashboardb.css';

import { useAuth } from "../../contexts/authContext";

const Dashboardb = () => {
  const navigate = useNavigate();
  const { hasCompletedOnboarding } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
  };

 useEffect(() => {
    if (!hasCompletedOnboarding) {
      setShowModal(true); // ✅ Show modal if onboarding is incomplete
    }
  }, [hasCompletedOnboarding]);

  const handleCompleteOnboarding = () => {
    setShowModal(false);
    navigate("/onboardbrands"); // ✅ Redirect to onboarding page
  };

 

  return (
    <div className="dashboard-container">
      <div className="dashboard-box">
        
          <h2>Welcome To Your Dashboard</h2>
          <p>Select one of our 
            budget-friendly options below to get started with your creator campaigns.
            <br></br>
           
            </p>
       
        <br />
        <div className="button-group">
          <div className="button-row">
            <button className="dashboard-button creditz" onClick={() => handleNavigation('/testcampaign')}>
            Test Campaigns <br />
            <span style={{ fontSize: '0.5em' }}>(For 1st time users)</span>

            </button>
            <button className="dashboard-button fullService" onClick={() => handleNavigation('/fsoverview')}>
              Full Service Plans
            </button>
            <button className="dashboard-button  creditz" onClick={() => handleNavigation('/testcoverview')}>
            Buy CREDITZ <br />
            <span style={{ fontSize: '0.5em' }}>(Pay-As-You-Go)</span>
</button>

          </div>
          <p style={{ fontSize: '15px', color: '#fc8320', fontStyle: 'bold', }}>  
            Have more questions? <a href="https://calendly.com/team-661/neufluence-full-service-discovery-call-15-minu-clone" target="_blank" rel="noopener noreferrer">
            Click here </a> to schedule a call.</p>
<p style={{ fontSize: '15px', color: '#2c3e50', fontStyle: 'bold', }}> 
            
            You can also email us at signup@neufluence.com</p>
        </div>
      </div>
      <Modal className="modal-content" show={showModal} onClose={handleCompleteOnboarding}>
        <p>Last step! <br></br> <br></br> We just need you to complete your profile before proceeding to the dashboard.</p>
        <button className="modal-close" onClick={handleCompleteOnboarding}>Go to Onboarding</button>
      </Modal>
    </div>
  );
};

export default Dashboardb;