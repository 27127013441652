

 const firebaseErrorMessages = (error) => {
    switch (error.code) {
        case 'auth/invalid-action-code':
            return 'The verification link is invalid or expired.';
        case 'auth/user-disabled':
            return 'Your account has been disabled by an administrator.';
        case 'auth/user-not-found':
            return 'No user found for this request.';
        case 'auth/email-already-in-use':
            return 'This email is already in use. Try logging in or resetting your password.';
        case 'auth/weak-password':
            return 'Password is too weak. Try using a stronger password.';
        case 'auth/invalid-email':
            return 'Invalid email format. Please enter a valid email address.';
        case 'auth/network-request-failed':
            return 'Network error. Please check your internet connection.';
        default:
            return 'An unexpected error occurred. Please try again later.';
    }
};

export default firebaseErrorMessages;