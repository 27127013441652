import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import './cssFiles/index.css'; // Ensure this line is present to include your CSS
import { AuthProvider } from "./contexts/authContext";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')); // Use createRoot
root.render(
    <React.StrictMode>
    <AuthProvider> {/* ✅ Wrap App with AuthProvider at the highest level */}
        <App />
        </AuthProvider>
    </React.StrictMode>
);
