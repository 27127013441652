import { useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../../cssFiles/questionnaire.css';

import LoadingSpinner from '../../components/LoadingSpinner';
import IndustrySelector from '../../components/IndustrySelector';
import * as Yup from "yup";
import { useAuth } from "../../contexts/authContext";


const Campaign = () => {
  /**
 * ✅ Brand Onboarding Validation Schema
 */
const BrandOnboardingSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Invalid phone number")
    .required("Phone Number is required"),
  industry: Yup.string().required("Industry is required"),
  companySize: Yup.string().required("Company size is required"),
  referredBy: Yup.string().required("Referral source is required"),
});


const { user, userData, hasCompletedOnboarding, saveBrandQuestionnaire, loading } = useAuth();
const navigate = useNavigate();

/**
 * ✅ Redirect users who already completed onboarding
 */
useEffect(() => {
  if (!user) return navigate("/login");

  if (hasCompletedOnboarding) {
    navigate("/brand-dashboard");
  }
}, [user, hasCompletedOnboarding, navigate]);

/**
 * ✅ Handles Form Submission: Saves to Firebase and Redirects
 */
const handleSubmit = async (values, { setSubmitting }) => {
  try {
    await saveBrandQuestionnaire(values);
    navigate("/brand-dashboard"); // Redirect after completion
  } catch (error) {
    console.error("Error saving brand questionnaire data:", error);
  }

  setSubmitting(false);
};

if (loading || !userData) return <LoadingSpinner />;

return (
  <Formik
    initialValues={{
      title: "",
      phoneNumber: "",
      industry: "",
      companySize: "",
      referredBy: "",
    }}
    validationSchema={BrandOnboardingSchema}
    onSubmit={handleSubmit}
  >
    {({ isSubmitting }) => (
      <Form className="questionnaire-style">
        <h3>Brand Onboarding</h3>
        <h3 className="questionnaire-style-heading">This is the Final Step!</h3>

        <div>
          <label>Name</label>
          <Field type="text" value={`${userData?.firstName} ${userData?.lastName}`} disabled />
        </div>

        <div>
          <label>Company</label>
          <Field type="text" value={userData?.businessName} disabled />
        </div>

        <div>
          <label>Email</label>
          <Field type="email" value={userData?.email} disabled />
        </div>

       {/* Editable Fields */}
       <label htmlFor="title">Title</label>
          <Field type="text" id="title" name="title" required />
          <ErrorMessage name="title" component="div" className="error" />

          <label htmlFor="phoneNumber">Phone Number</label>
          <Field type="text" id="phoneNumber" name="phoneNumber" required />
          <ErrorMessage name="phoneNumber" component="div" className="error" />

          <IndustrySelector />

          <label htmlFor="companySize">
            What is the size of your business? <span style={{ color: "#fc8320" }}>*</span>
          </label>
          <Field as="select" id="companySize" name="companySize" required>
            <option value="">Select your business size</option>
            <option value="0-10">0-10 employees</option>
            <option value="11-50">11-50 employees</option>
            <option value="51-150">51-150 employees</option>
            <option value="151+">151+ employees</option>
            <option value="other">Other</option>
          </Field>
          <ErrorMessage name="companySize" component="div" className="error" style={{ color: "red" }} />

          <label htmlFor="referredBy">
            How did you hear about us?
            <Field as="select" id="referredBy" name="referredBy">
              <option value="">Select a referral source</option>
              <option value="google">Google</option>
              <option value="other">Other</option>
              <option value="referral">Referral</option>
              <option value="socialMedia">Social Media</option>
              <option value="techstars">Techstars</option>
            </Field>
          </label>
          <ErrorMessage name="referredBy" component="div" className="error" />

        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Complete Onboarding"}
        </button>
      </Form>
    )}
  </Formik>
);
}



export default Campaign;

    