

// User clicks the "Buy Creditz" button → The modal appears.
//Stripe Pricing Table loads inside the modal via an embedded iframe.
// User selects a plan and completes the purchase via Stripe.
// Clicking the "✖" button closes the modal.

import React from 'react';

const PricingModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>✖</button>
        <h2>Buy Creditz</h2>
        {/* Embed the Stripe Pricing Table */}
        <iframe
          src="https://buy.stripe.com/embed/prctbl_1OyjmrG3BlD50Xxq0NrILxe4"
          width="100%"
          height="600px"
          frameBorder="0"
          scrolling="no"
          allow="payment"
        ></iframe>
      </div>
    </div>
  );
};

// Inline styles for modal
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '500px',
    textAlign: 'center',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '15px',
    background: 'none',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
};

export default PricingModal;
