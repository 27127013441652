import { useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import LoadingSpinner from '../../components/LoadingSpinner';
import IndustrySelector from '../../components/IndustrySelector';
import * as Yup from "yup";
import { useAuth } from "../../contexts/authContext";
import Modal from "../../components/Modal"; // ✅ Ensure you have a modal component
import '../../cssFiles/questionnaire.css';

const BrandsQ = () => {
 

/**
 * ✅ Brand Onboarding Validation Schema
 */
const BrandOnboardingSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Invalid phone number")
   ,
  industry: Yup.string().required("Industry is required"),
  companySize: Yup.string().required("Company size is required"),
  referredBy: Yup.string().required("Referral source is required"),
});


const { user, userData, hasCompletedOnboarding, saveBrandQuestionnaire, loading } = useAuth();
const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
const navigate = useNavigate();

/**
 * ✅ Redirect users who already completed onboarding
 */
useEffect(() => {
  if (!user) return navigate("/login");

  if (hasCompletedOnboarding) {
    navigate("/dashboardb");
  }
}, [user, hasCompletedOnboarding, navigate]);

/**
 * ✅ Handles Form Submission: Saves to Firebase and Redirects
 */
const handleSubmit = async (values, { setSubmitting }) => {
  try {
    await saveBrandQuestionnaire(values);
    setSuccessMessage('✅ Your profile is completed successfully! Returning to dashboard...');
    console.log('Setting modal open');
    setShowModal(true); // ✅ Show modal after successful submission
   
   setTimeout(() => {   
    console.log('Navigating to dashboard');
    setShowModal(false); // Hide modal after 4 seconds

    navigate("/dashboardb"); // Redirect after completion
  }, 4000);
  } catch (error) {
    console.error("Error saving brand questionnaire data:", error);
  }

  setSubmitting(false);
};

/**
   * ✅ Close modal function
   */
const handleCloseModal = () => {
  setShowModal(false);
  setSuccessMessage(false);
};


if (loading || !userData) return <LoadingSpinner />;

return (
  <div className="questionnaire-container">
  <Formik
    initialValues={{
      title: "",
      phoneNumber: "",
      industry: "",
      companySize: "",
      referredBy: "",
    }}
    validationSchema={BrandOnboardingSchema}
    onSubmit={handleSubmit}
  >
    {({ isSubmitting }) => (
      <Form className="questionnaire-style">

<div className="createhead">
        <h2>Finish You Brand Profile</h2>

        </div>
        <div className="questionnaire-style-heading">
        <p> Finish completing your profile. <br></br> After clicking "Submit", you will be redirected back to your dashboard.</p>
        
        </div>

       <div className="scrollable-content">

        <div>
          <label>Name</label>
          <Field type="text" value={`${userData?.firstName} ${userData?.lastName}`} disabled />
        </div>

        <br></br>
        <br></br>

        <div>
          <label>Business Name</label>
          <Field type="text" value={userData?.businessName} disabled />

          </div>
        <br></br>
        <br></br>

        <div>
          <label>Email</label>
          <Field type="email" value={userData?.email} disabled />
        </div>

        <br></br>
        <br></br>

       {/* Editable Fields */}
       <label htmlFor="title">Title</label>
          <Field type="text" id="title" name="title" required />
          <ErrorMessage name="title" component="div" className="error" />

          <br></br>
          <br></br>
          <br></br>

          <label htmlFor="phoneNumber">Phone Number</label>
          <Field type="text" id="phoneNumber" name="phoneNumber" required />
          <ErrorMessage name="phoneNumber" component="div" className="error" />
          <br></br>
          <br></br>
          <br></br>

          <IndustrySelector />
          <br></br>
 <br></br>

          <label htmlFor="companySize">
            What is the size of your business? <span style={{ color: "#fc8320" }}>*</span>
          </label>
          <Field as="select" id="companySize" name="companySize" required>
            <option value="">Select your business size</option>
            <option value="0-10">0-10 employees</option>
            <option value="11-50">11-50 employees</option>
            <option value="51-150">51-150 employees</option>
            <option value="151+">151+ employees</option>
            <option value="other">Other</option>
          </Field>
          <ErrorMessage name="companySize" component="div" className="error" style={{ color: "red" }} />

          <br></br>
          <br></br>
          <br></br>
          <label htmlFor="referredBy">
            How did you hear about us?
            <Field as="select" id="referredBy" name="referredBy">
              <option value="">Select a referral source</option>
              <option value="google">Google</option>
              <option value="other">Other</option>
              <option value="referral">Referral</option>
              <option value="socialMedia">Social Media</option>
              <option value="techstars">Techstars</option>
            </Field>
          </label>
          <ErrorMessage name="referredBy" component="div" className="error" />
          <br></br>
 </div>
 <br></br>
 <br></br>
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Complete Onboarding"}
        </button>

        <Modal show={showModal} onClose={handleCloseModal}>
        <div>
        <p>{successMessage}</p>
        <LoadingSpinner aria-label="Loading..." />
        </div>
      </Modal>
      </Form>
    )}
  </Formik>
  </div>
);
}

export default BrandsQ;
