import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../contexts/authContext"; // Import AuthContext
import { verifyPasswordResetCode, applyActionCode, getIdToken } from 'firebase/auth';
import LoadingSpinner from '../components/LoadingSpinner';
import { auth } from '../firebase';


const EmailisVerifiedMsg = () => {

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { setIsEmailVerified, user } = useAuth(); // ✅ Import user and setIsEmailVerified

   // ✅ Use `useRef` to persist BroadcastChannel
  const verificationChannel = useRef(null);

  useEffect(() => {

        // ✅ Initialize `BroadcastChannel` only once
        verificationChannel.current = new BroadcastChannel("email_verification_channel");


    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');

    console.log("🛠️ Debug: mode =", mode);
    console.log("🛠️ Debug: oobCode =", oobCode);

    if (!mode || !oobCode) {
      setError('Invalid or expired action code.');
      setIsLoading(false);
      return;
    }

    /**
     * ✅ Refresh user token before making Firebase requests
     */
    const refreshToken = async () => {
      if (user) {
        try {
          await getIdToken(user, true); // Force token refresh
          console.log("🔄 User token refreshed successfully");
        } catch (error) {
          console.error("🚨 Error refreshing user token:", error);
          setError("Session expired. Please log in again.");
          navigate("/login"); // Redirect to login if refresh fails
          return;
        }
      }
    };

    refreshToken()
      .then(() => {
        if (mode === 'resetPassword') {
          verifyPasswordResetCode(auth, oobCode)
            .then(() => {
              navigate(`/resetpassword?oobCode=${oobCode}`);
            })
            .catch(() => {
              setError('Invalid or expired action code.');
              setIsLoading(false);
            });
        } else if (mode === 'verifyEmail') {
          applyActionCode(auth, oobCode)
            .then(() => {
              console.log("✅ Email verification successful!");
              setMessage('Your email is verified!');

              // ✅ Update email verification state in authContext
              if (setIsEmailVerified) {
                setIsEmailVerified(true);
              }

              // ✅ Update localStorage so all tabs recognize verification
              localStorage.setItem("emailVerified", JSON.stringify(true));

               // ✅ Notify other tabs that email verification is complete
               if (verificationChannel.current) {
                verificationChannel.current.postMessage({ emailVerified: true });
              }

              // ✅ Redirect after 3 seconds
              setTimeout(() => navigate('/login'), 3000);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("🚨 Email verification error:", error);
              setError("Invalid or expired verification link.");
              setIsLoading(false);
            });
        } else {
          setError('Invalid action mode.');
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.error("🚨 Error during verification process:", error);
        setError("Verification process failed. Please try again.");
        setIsLoading(false);
      });

      return () => {
        // ✅ Close the channel when the component unmounts
        if (verificationChannel.current) {
          verificationChannel.current.close();
        }
      };
    }, [location, navigate, setIsEmailVerified, user]);

  return (
    <div className="form-style">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h1>{message || error}</h1>
          {message && (
            <p style={{ fontSize: '20px', color: '#2c3e50' }}>
              Your email is verified! You are now being redirected to log in to your account. <br></br>

              <p>Having trouble? Contact us at <span className="email-style">signup@neufluence.com</span>.</p>
            </p>

            
          )}
        </div>
      )}
    </div>
  );
};

export default EmailisVerifiedMsg;
