


import React, { useState } from "react";
import PricingModal from "./PricingModal";

const CreditzOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      <h2>Neufluence CREDITZ</h2>
      <p>
        Neufluence CREDITZ offers pay-as-you-go access to creator campaign tools and expertise, designed for budget-conscious startups and small businesses.
      </p>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => setIsModalOpen(true)}
        >
          Buy Creditz
        </button>
      </div>

      {/* Render the modal only when isModalOpen is true */}
      <PricingModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default CreditzOverview;
