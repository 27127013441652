import { createContext, useContext, useEffect, useState, useRef } from "react";
import { 
  auth, 
  db  } from '../firebase'; // Ensure db is your Firestore instance

import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged, 
  sendEmailVerification
 } from 'firebase/auth';
 import { 
  doc, 
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  arrayUnion, // tracks login history and devices
  collection,
  serverTimestamp
 } from 'firebase/firestore';

 
 const AuthContext = createContext();
 
 export function AuthProvider({ children }) {
   const [user, setUser] = useState(null);
   const [isAdmin, setIsAdmin] = useState(false); // ✅ New State for Admin Check
   const [userType, setUserType] = useState(null);
   const [loading, setLoading] = useState(true);
   const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);
   const [userData, setUserData] = useState(null);
   const [isEmailVerified, setIsEmailVerified] = useState(false);
 
   // Broadcast channel for email verification sync
   const verificationChannel = useRef(new BroadcastChannel("email_verification_channel"));

 
   /**
    * ✅ Fetch user document from Firestore upon login
    * ✅ Detects email verification changes in real-time
    */
   useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

                  // ✅ Correct Placement: Fetch email verification status *before* accessing Firestore
                  const emailVerified = currentUser.emailVerified;

        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);


  
        if (userSnap.exists()) {
          const userData = userSnap.data() || {}; // ✅ Ensures we always get an object

          setUserData(userData);
         setUserType(userData.userType || ""); // ✅ Defaults to empty string
         setHasCompletedOnboarding(userData.hasCompletedOnboarding ?? false); // ✅ Ensures it's always boolean

          // ✅ Determine if the user is an admin
          const isAdminUser = userData.userType === "admin";
          setIsAdmin(isAdminUser); // ✅ Update `isAdmin` state
  
          // ✅ Ensure Firestore `emailVerified` is updated only if necessary
          if (emailVerified && !userData.emailVerified) {
            try {
              await updateDoc(userRef, { emailVerified: true });
            } catch (error) {
              console.error("🚨 Error updating email verification in Firestore:", error);
            }
          }
  
          // ✅ Store email verification status in state and local storage
          setIsEmailVerified(emailVerified);
          localStorage.setItem("emailVerified", JSON.stringify(emailVerified));
  
          // ✅ Update lastSignon timestamp when user logs in
          await updateDoc(userRef, { lastSignon: serverTimestamp() });
  
          // ✅ Notify other tabs if email was just verified
          if (emailVerified) {
            try {
              verificationChannel.postMessage({ emailVerified: true });
              verificationChannel.close();
            } catch (error) {
              console.error("🚨 BroadcastChannel error:", error);
            }
          }
        } else {
          console.warn("🚨 No Firestore document found for user:", currentUser.uid);
  
          setUserData(null);
          setUserType(null);
          setIsAdmin(false); // ✅ Reset `isAdmin`
          setHasCompletedOnboarding(false);
          setIsEmailVerified(false);
        }
      } else {
        // ✅ User is signed out: Reset all authentication-related state
        setUser(null);
        setUserData(null);
        setUserType(null);
        setHasCompletedOnboarding(false);
        setIsAdmin(false); // ✅ Reset `isAdmin`
        setIsEmailVerified(false);
        localStorage.removeItem("emailVerified");
      }
  
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, [auth]);



     /**
    * ✅ Listen for email verification status from other tabs
    */
   useEffect(() => {
     verificationChannel.onmessage = (event) => {
       if (event.data.emailVerified) {
         setIsEmailVerified(true);
         localStorage.setItem("emailVerified", JSON.stringify(true));
      }
    };

    return () => {
      if (verificationChannel.current) {
        verificationChannel.current.close();
      }
    };
  }, []);
 
   /** ✅ Save Test Campaign Data to Firestore */
   const saveTestCampaign = async (values) => {
     if (!user) return;
 
     try {
       const campaignsRef = collection(db, "users", user.uid, "campaigns"); // Subcollection under user doc
       await addDoc(campaignsRef, {
         ...values,
         createdAt: serverTimestamp(),
       });
     } catch (error) {
       console.error("Error saving test campaign data:", error);
     }
   };
 
   /**
    * Sign-up function that saves user data to Firestore.
    * Includes userType (brand/creator) and a createdAt timestamp.
    */
   const signUp = async (values) => {
     const { email, password, firstName, lastName, userType, businessName, businessWebsite, country, state, city } = values;
     
     try {
       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
       const userId = userCredential.user.uid;
 
       // Save additional user data to Firestore
       await setDoc(doc(db, "users", userId), {
         firstName,
         lastName,
         email,
         userType,
         country,
         state,
         city,
         ...(userType === "brand" && { businessName, businessWebsite }), // Include business fields only for brands
         hasCompletedOnboarding: false, // Default value
         agreedToTerms: true,
         createdAt: serverTimestamp(),  //stores when account is created.
         lastSignon: serverTimestamp(), //stores initial login time
       });
 
       // Send email verification
       await sendEmailVerification(userCredential.user);
     
       // Return success so Formik can reset the form
       return { success: true };
     } catch (error) {
       console.error("Sign-up error:", error);
       throw error;
     }
   };
 
   /**
    * Sign-in function
    */
   const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // ✅ Correctly store login history
    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
      lastSignon: serverTimestamp(), // ✅ Correct usage
      loginHistory: arrayUnion(new Date().toISOString()) // ✅ Use a JavaScript date instead
    });

    return user;
  } catch (error) {
    console.error("Error during sign-in:", error);
    throw error;
  }
  };

  
   /**
    * ✅ Save Brand Questionnaire Data to Firestore
    * @param {Object} values - The form values submitted by the brand user
    */
   const saveBrandQuestionnaire = async (values) => {
     if (!user) return;
 
     try {
       const userRef = doc(db, "users", user.uid);
       await updateDoc(userRef, {
         ...values,
         hasCompletedOnboarding: true,
       });
 
       // Update local state so UI reflects changes immediately
       setUserData((prevData) => ({
         ...prevData,
         ...values,
         hasCompletedOnboarding: true,
       }));
 
       setHasCompletedOnboarding(true);
     } catch (error) {
       console.error("Error saving brand questionnaire data:", error);
     }
   };
 
   
 
   /**
    * ✅ Sign-out function
    */
   const signOutUser = async () => {
     await signOut(auth);
     setUser(null);
     setUserData(null);
     setUserType(null);
     setHasCompletedOnboarding(false);
     setIsEmailVerified(false);
   };
 
   return (
     <AuthContext.Provider value={{ 
       user, 
       userData, 
       userType, 
       hasCompletedOnboarding, 
       signUp, 
       signIn, 
       signOutUser, 
       isAdmin, // ✅ Provide `isAdmin` to all components
       isEmailVerified, 
       setIsEmailVerified, 
       saveBrandQuestionnaire, 
       saveTestCampaign, 
       loading 
     }}>
       {children}
     </AuthContext.Provider>
   );
 }
 
 export function useAuth() {
   return useContext(AuthContext);
 }
 