
// Plan Details: The component defines an array of plan objects, each containing the title, description, and duration of the plan.

// Common Inclusions: An array lists the features included in all full-service plans.

// Rendering: The component maps over the plans and commonInclusions arrays to dynamically render the list items.

// Links: Relevant links are provided for scheduling a call and celebrity booking.




import React from 'react';

const FullServicePlans = () => {
  const plans = [
    {
      title: 'Intro Plan',
      description: 'For businesses and startups just getting started with creator campaigns.',

    },

    {
      title: 'Early Stage Plan',
      description: 'For businesses and startups looking for a supplemental team member to help with the hard parts of running a campaign.'
 
    },
    {
      title: 'Business Plan', 
      description: 'For businesses and startups looking for an expert for end-to-end campaign management.',

    },
  ];

  const commonInclusions = [
    'Curated Creator List: A list of curated creators. The number of creators is based on your plan.',
    'Work Plan: An outline of how we will run your campaign.',
    '2 Kick-Off Calls: Structured to outline your campaign, set you up, and review all components.',
    'Weekly Check-in: Once a week, we will hold a call to review campaign updates and questions.',
    'Customized Status Updates: In addition to 24-hour creator pipeline access, we offer customization of your pipeline updates from daily, weekly, to bi-weekly.',
  ];

  return (
    <div className="dashboard-container">
          <div className="forms-box">
          <div className="createhead">
      <h2>Full-Service Campaigns</h2>
      </div>
      <p>
        Let the experts handle your difficult-to-book campaigns. Upgrade to Neufluence's full-service plan and let our experts handle your comprehensive campaign management needs, from agreement drafting to payment negotiations, suitable for both budget-conscious small and large-scale teams.
      </p>
      <h3>Plan Durations</h3>
      <p>Plan durations are 2-months, 3-months, and 6-months.</p>
      <ul>
        {plans.map((plan, index) => (
          <li key={index}>
            <strong>{plan.title} ({plan.duration}):</strong> {plan.description}
          </li>
        ))}
      </ul>
      <h3>What's Included in All Full-Service Plans</h3>
      <ul>
        {commonInclusions.map((inclusion, index) => (
          <li key={index}>{inclusion}</li>
        ))}
      </ul>
      <p>
        Plans start with a 2-month minimum duration. Prior to scheduling a call, you will be asked a few details about your business.
      </p>
      <p>
        Interested in booking a celebrity? <a href="https://neufluence.com/celebrity-booking">Click here</a>.
      </p>
      <p>
      <div className="button-container">
          <a href="https://neufluence.com/schedule-call" className="button">Schedule a Call</a>
          <br></br>
          <a href="https://neufluence.com/buy-plan" className="button">Buy a Plan</a>
        </div>
      </p>
    </div>
    </div>
  );
};

export default FullServicePlans;


