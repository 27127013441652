import * as Yup from 'yup';

const websiteValidation = Yup.string()
  .test(
    'is-valid-url',
    'Invalid website format. Please enter a correct website address.',
    (value) => {
      if (!value) return true; // Allow empty field (let .required() handle it)
      
      // Automatically add 'https://' if missing
      const formattedValue = value.startsWith('http://') || value.startsWith('https://')
        ? value
        : `https://${value}`;

      // Basic regex to check valid domain
      const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
      return urlRegex.test(formattedValue);
    }
  )
  .required('Website is required');

  
// Email validation schema
const emailValidation = Yup.string()
  .email('Invalid email format.') // Built-in email validation
  .test(
    'not-disposable',
    'Temporary email addresses are not allowed.',
    (value) => {
      if (!value) return true; // If value is empty, let the required() rule handle it
      const domain = value.split('@')[1]; // Extract the domain part of the email
      return !blockedDomains.includes(domain); // Return false if domain is in the blocked list
    }
  )
  .required('Email is required');

  const blockedDomains = [
    'mailinator.com', 'tempmail.com', 'guerrillamail.com', '10minutemail.com',
    'yopmail.com', 'throwawaymail.com', 'getnada.com', 'dispostable.com',
    'sharklasers.com', 'spambox.us'
  ];

  
  // Password validation schema
  const passwordValidation = Yup.string()
  .min(7, 'Password must be at least 7 characters long.')
  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
  .matches(/[0-9]/, 'Password must contain at least one number.')
  .matches(/[\W_]/, 'Password must contain at least one special character.') // Ensures at least one special character
  .required('Password is required');


// Confirm password validation schema
const confirmPasswordValidation = Yup.string()
  .when('password', (password, schema) => 
    password ? schema.oneOf([Yup.ref('password')], 'Passwords do not match.') : schema
  )
  .required('Password confirmation is required');


    // Signup schema
    export const SignupSchema = Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      companyName: Yup.string().required('Company Name is required'),
      email: emailValidation,
      website: websiteValidation,
      selectCountry: Yup.string().required('Country is required'),
      selectState: Yup.string().required('State is required'),
      selectCity: Yup.string().required('City is required'),
      password: passwordValidation,
      confirmPassword: confirmPasswordValidation,
      referredBy: Yup.string(),
    });

    export const CreatorSignupSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too short').required('Required'),
    lastName: Yup.string().min(2, 'Too short').required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    country: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
  });
  
// Sign-in schema
export const SignInSchema = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

/**
 * ✅ Brand Signup Validation Schema
 * - Ensures required fields are filled.
 * - Validates email, password, and website format.
 * - Includes honeypot protection (`fakeField`).
 * - Ensures the user agrees to terms (`agreedToTerms`).
 */
export const BrandSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  businessName: Yup.string().required("Required"),
  businessWebsite: Yup.string()
    .url("Invalid URL format. Please enter a valid URL.")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6, "Minimum 6 characters").required("Required"),
  fakeField: Yup.string().max(0, "Invalid input"), // ✅ Honeypot field should remain empty
  agreedToTerms: Yup.boolean()
    .oneOf([true], "You must agree to the terms and conditions") // ✅ Ensures checkbox is checked
    .required("Required"),
});