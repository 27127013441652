//This will prevent users from navigating backward using the browser back button.

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const usePreventBackNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate(1); // ✅ Prevents going back
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);
};

export default usePreventBackNavigation;
