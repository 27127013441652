

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../contexts/authContext"; // Import AuthContext
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../../components/LoadingSpinner';
import IndustrySelector from '../../components/IndustrySelector';
import '../../cssFiles/account.css';
import '../../cssFiles/questionnaire.css';
/**
 * ✅ Test Campaign Validation Schema
 */
const TestCampaignSchema = Yup.object().shape({
  postsRequired: Yup.number()
    .min(1, "At least 1 post is required")
    .required("This field is required"),
  postingStart: Yup.date().required("Please select a start date"),
  postingEnd: Yup.date()
    .required("Please select an end date")
    .min(Yup.ref("postingStart"), "End date must be after start date"),
  campaignDesc: Yup.string().required("Please provide a campaign description"),
  creatorsNum: Yup.number()
    .min(1, "At least 1 creator is required")
    .required("This field is required"),
  feeRange: Yup.string().required("Please provide a fee range"),
  campaignBudget: Yup.number()
    .min(1, "Budget must be at least $1")
    .required("This field is required"),
  campaignPreference: Yup.string().required("Please select an option"),
});

export default function TestCampaign() {
  const { user, saveTestCampaign, loading } = useAuth();
  const navigate = useNavigate();

  if (loading) return <LoadingSpinner />; // Show loading spinner until data is ready

  return (
    <Formik
      initialValues={{
        postsRequired: "",
        postingStart: "",
        postingEnd: "",
        campaignDesc: "",
        creatorsNum: "",
        feeRange: "",
        campaignBudget: "",
        campaignPreference: "",
      }}
      validationSchema={TestCampaignSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await saveTestCampaign(values);
          resetForm();
          navigate("/dashboardb"); // Redirect after submission
        } catch (error) {
          console.error("Error saving campaign:", error);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="forms-style">
          <div className="dashboard-container">
          <div className="forms-box">
          <div className="createhead">
          <h2>Tell Us About Your Campaign</h2>
          </div>
          <p>Complete this short overview so we can provide a campaign assessment.</p>
        <br></br>

        <div className="scrollable-content">
          <label htmlFor="postsRequired"># of posts required for the campaign
          <Field type="number" id="postsRequired" name="postsRequired" required />
          </label>
          <ErrorMessage name="postsRequired" component="div" className="error-messsage" />

          <label htmlFor="postingStart">What date should creators start posting?
          <Field type="date" id="postingStart" name="postingStart" required />
          </label>
          <ErrorMessage name="postingStart" component="div" className="error-messsage" />
          <br>
          </br>
          <label htmlFor="postingEnd">When does your campaign end?</label>
          <Field type="date" id="postingEnd" name="postingEnd" required />
          <ErrorMessage name="postingEnd" component="div" className="error-messsage" />
          <br>
          </br>
          <label htmlFor="campaignDesc">Campaign Description</label>
          <Field as="textarea" id="campaignDesc" name="campaignDesc" required />
          <ErrorMessage name="campaignDesc" component="div" className="error-messsage" />

          <h3>CREATOR CRITERIA</h3>
          <p>We are only focusing on micro or nano creators. Provide a few details below.</p>

          <label htmlFor="creatorsNum">Target # of Creators to participate
          <Field type="number" id="creatorsNum" name="creatorsNum" required />
          </label>
          <ErrorMessage name="creatorsNum" component="div" className="error-messsage" />
          <br>
          </br>

          <IndustrySelector />
          <br>
          </br>

          <label htmlFor="feeRange">Provide a creator fee range.</label>
          <Field type="text" id="feeRange" name="feeRange" required />
          <ErrorMessage name="feeRange" component="div" className="error-messsage" />
          <br>
          </br>
          <label htmlFor="campaignBudget">What is your overall budget?</label>
          <Field type="number" id="campaignBudget" name="campaignBudget" required />
          <ErrorMessage name="campaignBudget" component="div" className="error-messsage" />
          <br>
          </br>
          <label htmlFor="campaignPreference">
            Are you looking to run your campaign independently, or would you prefer our expert team to handle it?
          </label>
          <Field as="select" id="campaignPreference" name="campaignPreference">
            <option value="">Select an option</option>
            <option value="independent">Run campaign independently</option>
            <option value="expert">Have expert team handle it</option>
          </Field>
          <ErrorMessage name="campaignPreference" component="div" className="error-messsage" />
          </div>

          <br></br>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit Campaign"}
          </button>

          </div>
          </div>
        </Form>
       
      )}
    </Formik>
  );
}
